import { useMemo, useRef, useState, useEffect } from 'react';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { AddressFormData, ValidatedAddressData } from './common/helpers/utils';
import { AddressForm } from './components/AddressForm/AddressForm';
import { ApiPaths, ApplicationAPI } from './api/api';

import './App.scss';

const DEFAULT_TIMEOUT = 3000;

enum AddressesSaveTypes {
  Original = 'original',
  USPS = 'usps',
}

function App() {
  const formRef = useRef(null);
  const messageTimeout = useRef<any>(null);
  const [address, setAddress] = useState<AddressFormData | null>(null);
  const [parsedAddress, setParsedAddress] = useState<ValidatedAddressData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [saveType, setSaveType] = useState(AddressesSaveTypes.Original);
  const [states, setStates] = useState<string[]>([]);


  const catchError = async (response: Response) => {
    clear();
    const errorPayload: unknown = await response.json();
    setError(ApplicationAPI.parseError(errorPayload));
  }

  const onSubmit = async (data: AddressFormData) => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    setAddress(data);
    const response = await ApplicationAPI.get(ApiPaths.Validate, ApplicationAPI.generateParams<AddressFormData>(data));
    setLoading(false);

    if (response.status !== 200) {
      catchError(response);
      return;
    }

    const result: ValidatedAddressData = await response.json();
    setParsedAddress(result);
  }

  const save = async (data: AddressFormData) => {
    const response = await ApplicationAPI.post<AddressFormData>(ApiPaths.Create, data);
    if (response.status !== 201) {
      catchError(response);
      return;
    }
    clear();

    if (typeof formRef.current === "function") {
      (formRef.current as Function)();
    }
    setMessage('Successfully added');
    messageTimeout.current = setTimeout(() => {
      setMessage(null);
    }, DEFAULT_TIMEOUT);
  }

  const handleSave = async () => {
    if (!parsedAddress || !address) {
      return;
    }
    const payload: AddressFormData = {
      address1: saveType === AddressesSaveTypes.Original ? address.address1 : parsedAddress.address1,
      address2: saveType === AddressesSaveTypes.Original ? address.address2 : parsedAddress.address2,
      city: saveType === AddressesSaveTypes.Original ? address.city : parsedAddress.city,
      state: saveType === AddressesSaveTypes.Original ? address.state : parsedAddress.state,
      zipcode: saveType === AddressesSaveTypes.Original ? address.zipcode : parsedAddress.zip4
    }
    save(payload);
  }

  const clear = () => {
    setAddress(null);
    setParsedAddress(null);
    setError(null);
    setMessage(null);
  }

  const canShowSaveButton = useMemo<boolean>(() => {
    return !!address && !!parsedAddress
  }, [address, parsedAddress]);

  const fetchStates = async () => {
    const response = await ApplicationAPI.get(ApiPaths.States, new URLSearchParams({ format: 'json' }));
    const states = await response.json();
    setStates(states);
  }

  useEffect(() => {
    fetchStates();
    return () => {
      if (messageTimeout.current) {
        messageTimeout.current.clear();
      }
    }
  }, []);

  const getVariant = (buttonType: AddressesSaveTypes): string => {
    return saveType === buttonType ? 'primary' : 'outline-primary'
  }

  const setVariant = (buttonType: AddressesSaveTypes) => (): void => {
    setSaveType(buttonType);
  }

  const drawAddressVariant = () => {
    switch (saveType) {
      case AddressesSaveTypes.Original:
        return (
          <>
            <Row>
              <Col xs="12">Address Line 1: <b>{address?.address1}</b></Col>
            </Row>
            <Row>
              <Col xs="12">Address Line 2: <b>{address?.address2}</b></Col>
            </Row>
            <Row>
              <Col xs="12">City: <b>{address?.city}</b></Col>
            </Row>
            <Row>
              <Col xs="12">State: <b>{address?.state}</b></Col>
            </Row>
            <Row>
              <Col xs="12">Zip Code: <b>{address?.zipcode}</b></Col>
            </Row>
          </>
        )
      case AddressesSaveTypes.USPS:
        return (
          <>
            <Row>
              <Col xs="12">Address Line 1: <b>{parsedAddress?.address1}</b></Col>
            </Row>
            <Row>
              <Col xs="12">Address Line 2: <b>{parsedAddress?.address2}</b></Col>
            </Row>
            <Row>
              <Col xs="12">City: <b>{parsedAddress?.city}</b></Col>
            </Row>
            <Row>
              <Col xs="12">State: <b>{parsedAddress?.state}</b></Col>
            </Row>
            <Row>
              <Col xs="12">Zip Code: <b>{parsedAddress?.zip4}</b></Col>
            </Row>
          </>
        )
    }
  }

  return (
    <ThemeProvider>
      <Col lg="4" md="8" xs="11" className="mx-auto my-4 card shadow">
        <h1 className="d-grid title">Address Validator</h1>
        <h1 className="d-grid description">Validate/Standardizes addresses using USPS</h1>
        <hr />
        <AddressForm resetTrigger={formRef} isLoading={isLoading} onSubmit={onSubmit} data={states} />
        <Modal show={canShowSaveButton}>
          <Modal.Header closeButton>
            <Modal.Title>Save Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs="12">Which address format do you want to save?</Col>
              </Row>
              <Row>
                <ButtonGroup className="my-2">
                  <Button variant={getVariant(AddressesSaveTypes.Original)} onClick={setVariant(AddressesSaveTypes.Original)}>ORIGINAL</Button>
                  <Button variant={getVariant(AddressesSaveTypes.USPS)} onClick={setVariant(AddressesSaveTypes.USPS)}>STANDARDIZED (USPS)</Button>
                </ButtonGroup>
              </Row>
              <div className="card">
                {drawAddressVariant()}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Modal>
      </Col>
      {error && <div className="alert-box"><Alert variant="danger">{error}</Alert></div>}
      {message && <div className="alert-box"><Alert>{message}</Alert></div>}
    </ThemeProvider>
  );
}

export default App;
