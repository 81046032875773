import React from 'react';
import Form from 'react-bootstrap/Form';
import { UseFormRegisterReturn } from "react-hook-form";

import './Field.scss';

enum FieldTypes {
  Text = "text",
}

interface FieldProps {
  label: string | React.ReactNode,
  type?: string,
  placeholder?: string,
  register: UseFormRegisterReturn,
  required?: boolean,
}

const Field = ({ label, type = FieldTypes.Text, placeholder, register, required = false }: FieldProps) => {
  return (
    <Form.Group className="mb-1">
      <Form.Label className="label">{label}<span className="required">{required && " *"}</span></Form.Label>
      <Form.Control className="form-control-sm" {...register} type={type} placeholder={placeholder} required={required} />
    </Form.Group>
  )
}

export {
  Field,
}
