enum ApiPaths {
  Validate = 'address/validate/',
  Create = 'address/create/',
  States = 'address/state/list/',
}

class API {
  baseURL = process.env.REACT_APP_BACKEND_BASE_URL
  error: string | null = null

  generateParams<T extends Record<string, any>>(data: T) {
    const searchParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      searchParams.set(key, value);
    })
    return searchParams;
  }

  parseError(errorData: unknown) {
    if (typeof errorData === 'string') {
      return errorData;
    }
    if (typeof errorData === 'object' && errorData && !Array.isArray(errorData)) {
      let errorText = '';
      Object.entries(errorData).forEach(([key, value]: [string, unknown]) => {
        errorText += `${key}: ${value}`;
      })
      return errorText;
    }
    return 'Unknown Error';
  }

  async get(url: string, params: URLSearchParams = new URLSearchParams()) {
    const response = await fetch(`${this.baseURL}/${url}?${params?.toString()}`)
    return response;
  }

  async post<T>(url: string, data: T) {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }
    const response = await fetch(`${this.baseURL}/${url}`, options)
    return response;
  }
}

const ApplicationAPI = new API();

export {
  ApplicationAPI,
  ApiPaths,
}
