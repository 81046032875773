import { ErrorMessage } from "@hookform/error-message"
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { Field } from "../../common/field/Field"
import { AddressFormData } from "../../common/helpers/utils";

const AddressForm = ({ onSubmit, resetTrigger, data, isLoading }: { onSubmit: (data: AddressFormData) => void, data: string[], resetTrigger: any, isLoading: boolean }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<AddressFormData>();

  useEffect(() => {
    resetTrigger.current = reset;
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field label="Address Line 1" placeholder="Address" register={register("address1", { required: true })} required />
      <ErrorMessage className="error" as="span" errors={errors} name="address1" message="Required" />
      <Field label="Address Line 2" placeholder="Address" register={register("address2", { required: true })} required />
      <ErrorMessage className="error" as="span" errors={errors} name="address2" message="Required" />
      <Field label="City" placeholder="San Francisco" register={register("city", { required: true })} required />
      <ErrorMessage className="error" as="span" errors={errors} name="city" message="Required" />
      <Form.Group className="mb-1">
        <Form.Label className="label">State<span className="required"> *</span></Form.Label>
        <Form.Select size="sm" id="select" {...register("state", { required: true })} required>
          {data.map((key: string) => <option value={key}>{key}</option>)}
        </Form.Select>
      </Form.Group>
      <ErrorMessage className="error" as="span" errors={errors} name="state" message="Required" />
      <Field label="Zip Code" placeholder="00000" register={register("zipcode", { required: true })} required />
      <ErrorMessage className="error" as="span" errors={errors} name="zipcode" message="Required" />
      <div className="d-grid mt-4">
        <Button size="lg" variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Validate' }
        </Button>
      </div>
      </Form>
  )
}

export {
  AddressForm,
}
